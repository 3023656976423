import { Store } from 'vuex';

import { USER_LOGOUT } from '../../../core/store/modules/coreModule';
import { clearFnCache, memoizePromise } from '../../../utils/memoizePromise';
import { AttributeValues, Form } from '../api/attributes';
import formsApi from '../api/forms';
import { AttributeTypes, DisplayMode } from '../constants';
import { iterateAttrGroup } from '../helpers/formsHelper';
import { IFormsService } from './types';

/**
 * Separator for combo value
 */
const COMBO_VALUE_SEPARATOR = ',';

export class FormsService implements IFormsService {
  /**
   * @param store - vuex store
   */
  constructor(private readonly store: Store<unknown>) {
    store.subscribeAction((action) => {
      if (action.type === USER_LOGOUT) {
        this.clearCache();
      }
    });
  }

  /**
   * @inheritdoc
   */
  public getFormByAlias: (formAlias: string) => Promise<Form.Form> = memoizePromise(
    async (formAlias: string) => {
      return await formsApi.getFormById(formAlias);
    }
  );

  /**
   * Clears cache
   */
  private clearCache() {
    clearFnCache(this.getFormByAlias);
  }

  /**
   * @inheritdoc
   */
  public getDefaultValues(
    attributeGroups: Pick<Form.AttributeGroup, 'attributes'>[]
  ): Record<string, AttributeValues.AttributeValue> {
    // Grab user extended properties
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const extendedProperties = this.store.getters.user.extendedProperties as Record<
      string,
      AttributeValues.AttributeValue
    >;
    // Clone user props to prevent edit form > edit ext props
    const cloned = extendedProperties
      ? (JSON.parse(JSON.stringify(extendedProperties)) as Record<
          string,
          AttributeValues.AttributeValue
        >)
      : {};

    // Iterate over values to replace combo values with their multiple value
    const attributesMap = {} as Record<string, Form.Attribute>;
    iterateAttrGroup(attributeGroups, (attr) => {
      attributesMap[attr.alias] = attr;
    });

    for (const alias of Object.keys(cloned)) {
      const value = cloned[alias];
      const attribute = attributesMap[alias];
      if (
        attribute &&
        attribute.type === AttributeTypes.LINKS &&
        attribute.options?.displayMode === DisplayMode.Combo &&
        typeof value === 'string'
      ) {
        cloned[alias] = value.split(COMBO_VALUE_SEPARATOR);
      }
    }

    return cloned;
  }
}
